import React, { Suspense } from "react";
import style from './index.module.less'
import { Canvas } from "@react-three/fiber"
import Models from "./Models"

const isBrowser = typeof window !== "undefined" && window
const Scroll = () => {
    return (
        <div className={style.canvasBox}>
            <Canvas
                transparent
                gl={{ antialias: false }}
                dpr={window.devicePixelRatio}
                camera={{
                    fov: 45,
                    aspect: isBrowser ? (window.innerWidth / window.innerHeight) : 1,
                    near: 0.1,
                    far: 10000,
                    position: [0, 0, 0],
                    rotateZ: 0,
                    rotateX: 0,
                    rotateY: 0
                }}
            // style={{ height: '100vh', position: "absolute", top: "-24vh" }}
            >
                <pointLight color="#FFFFFF" />
                <Suspense fallback={null}>
                    <Models />
                </Suspense>
            </Canvas>
        </div>
    )
}

export default Scroll
