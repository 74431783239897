import React from "react";
import { useIntl } from "react-intl";
import style from './index.module.less'


const CompatibilityMobileList = ({ data }) => {
    const intl = useIntl()
    return (
        <div className={style.box}>
            {/* <div className={style.time}>{data.time}</div> */}
            <div className={style.table}>
                <div className={style.tableHeader}>
                    <div className={style.tableHeaderItem}>
                        {intl.formatMessage({ id: "compativility_model_name", defaultMessage: "Model Name" })}
                    </div>
                    <div className={style.tableHeaderItem}>
                        {intl.formatMessage({ id: "compativility_model_num", defaultMessage: "Model Number" })}
                    </div>
                </div>
                <div className={style.tableBody}>
                    {data.map((item, index) => (
                        <div className={style.tableBodyTr} key={`compatibility${index}`} style={{ backgroundColor: !(index % 2) ? 'rgba(0, 0, 0, 0.05)' : "#fff" }}>
                            <div className={style.tableBodyTrLeft}>
                                {item.modelName}
                            </div>
                            <div className={style.tableBodyTrRight}>

                                <div className={style.tableBodyTrRightItem} key={item.modelNumber}>
                                    {item.modelNumber}
                                </div>

                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default CompatibilityMobileList