import React from 'react'
import style from "./index.module.less"
import { useIntl } from 'react-intl'
import comcss from "@/components/Home/index.module.less"
import useBigScreen from "@/hooks/bigScreen"
let arpcjpg = `${process.env.resourceUrl}images/ar/ar_pc.webp`
let armobjpg = `${process.env.resourceUrl}images/ar/ar_mob.webp`

const Index = () => {
    const intl = useIntl()
    const bigscreen = useBigScreen(768)
    return <div className={style.block} style={{ backgroundImage: `url(${bigscreen ? arpcjpg : armobjpg})` }}>
        <div className={style.blockContent}>
            <div className={style.blockContentText}>
                <h2
                    className={comcss.titlefff}
                >
                    {intl.formatMessage({ id: "ar.Whatisar" })}
                </h2>
                <div className={`${style.blockContentTextDes} ${comcss.descfff}`}>
                    <div className={style.blockContentTextDesItem}>{intl.formatMessage({ id: "ar.Whatisar.des1" })}</div>
                    <div className={style.blockContentTextDesItem}>{intl.formatMessage({ id: "ar.Whatisar.des2" })}</div>
                </div>
            </div>
        </div>
    </div>
}
export default Index