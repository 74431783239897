import React, { useState, useEffect } from 'react'
import style from "./index.module.less"
import { CompatibilityMobileList } from '@/components/index'
import { getAdaptByCountry } from '@/services/request'
import getCountry from '@/utils/getCountry.js'
import { useIntl } from 'react-intl'
import comcss from "@/components/Home/index.module.less"
import { getCompatibilityCode } from '@/utils/regions.js'
const Index = () => {
    const intl = useIntl()
    const [comList, setComList] = useState([])
    const compCode = getCompatibilityCode()
    useEffect(() => {
        getAdaptByCountry({ countryCode: compCode }).then(
            (res) => {
                let { code, data } = res.data
                if (code == 200) {
                    let allList = data
                    let airList = allList?.filter(item => item?.devices?.includes('XREAL Air'))
                    let aircastingList = airList?.filter(item => item.mrspace === true)
                    setComList(aircastingList)
                }
            }
        )

    }, [])
    return <>
        {comList.length !== 0 && <div className={style.block} >

            <h2
                className={comcss.subtitle}
            >{intl.formatMessage({ id: "ar.Compatibility" })}</h2>
            <div
                className={comcss.subdesc}
            >{intl.formatMessage({ id: "ar.Compatibility.des" })}</div>
            <h3 className={style.blockSubTitle}>{intl.formatMessage({ id: "ar.Compatibility.title" })}</h3>
            <CompatibilityMobileList data={comList} />
        </div>}
    </>
}
export default Index