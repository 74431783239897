import React from 'react';
import { Layout, SEO } from '@/components/index'
import { WahtIsAR, MultiWindows, ARspace, ARlab, Compatibility } from "@/components/AR/index"
import { useInView } from "react-intersection-observer"

const Index = () => {
    const [whatRef, whatInView] = useInView({ threshold: 0 });
    return <>
        <Layout menuBlack={!whatInView ? true : false} >
            <SEO page="AR" />
            <div ref={whatRef}>
                <WahtIsAR />
            </div>
            <MultiWindows />
            <ARspace />
            <ARlab />
            <Compatibility />
        </Layout>
    </>
}
export default Index